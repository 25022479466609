document.addEventListener('turbo:load', () => {
  const sortDropdown = document.getElementById('sort-lead-dropdown');
  const updateResults = function() {
    const selectedSortValue = sortDropdown.value;

    const redirectUrl = `/api/leads/filter_sort?sort=${selectedSortValue}`;

    // Redirect to the new URL
    window.location.href = redirectUrl;
  };
  sortDropdown.addEventListener('change', updateResults);
  document.addEventListener('DOMContentLoaded', function () {
    var checkmark = document.getElementById('checkmark-lead-status');
    if (checkmark) {
        checkmark.style.display = 'none';
    }
  });
})
$("#contact_status").on("change", function(event) {
  var checkmark = document.getElementById('checkmark-lead-status');
  const selectedValue = $(this).val();

  setTimeout(function() {
    checkmark.style.display = 'none';
  }, 2000);
  if (selectedValue) {
      checkmark.style.display = 'inline-block';
  } else {
      checkmark.style.display = 'none';
  }
  var contactId = $("#contact_id").val();
  var statusValue = $(this).val();
  if (!statusValue && statusValue !== 0) {
      statusValue = "";
  }
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  $.ajax({
    url: "/leads/" + contactId + ".json",
    type: "PUT",
    data: {
      authenticity_token: csrf,
      id: contactId,
      contact: {
          status: statusValue
      }
    }
  });
});
$(document).on("click", ".ofac_check.contacts", function (e) {
  e.preventDefault();

  var last_name = $("#contact_last_name").val();
  var first_name = $("#contact_first_name").val();
  var company_name = $("#contact_company_name").val();
  var id = $("#contact_id").val();
  var spinner = $(this).closest(".card-body").find(".spinner-ofac");
  spinner.show();

  $.ajax({
    url: "/leads/ofac_check.json",
    type: "POST",
    data: {
      authenticity_token: document
        .querySelector("meta[name='csrf-token']")
        .getAttribute("content"),
      contact: {
        id: id,
        last_name: last_name,
        first_name: first_name,
        company_name: company_name,
      },
    },
    success: function (data, status, xhr) {
      setTimeout(function () {
        spinner.hide();
      }, 500);
      $("#ofac_status").text(data.status);
      $("#ofac_status").effect("highlight");
      $("#ofac_last_checked").text(data.last_ran);
      $("#contact_ofac_passed").val(data.ofac_passed);
      $("#contact_ofac_last_run").val(data.ofac_last_run_date);
    },
  });
});
$(document).on('turbo:load', function() {
  const selectAllCheckbox = $('#select-all');
  const tableBody = $('#lead-table tbody');
  const loadingIcon = $('.loading-icon');
  let loading = false;

  // Function to refresh rowCheckboxes
  function refreshRowCheckboxes() {
    return $('.lead-checkbox');
  }

  // Function to update the selected lead count
  function updateSelectedLeadCount() {
    const checkedCheckboxes = refreshRowCheckboxes().filter(':checked').length;
    $('#selected-lead-count').text(checkedCheckboxes);
    toggleBulkActions(checkedCheckboxes); // Check the count and update visibility
  }

  // Function to toggle bulk actions visibility
  function toggleBulkActions(checkedCount) {
    if (checkedCount === 0) {
        $('.bulk-actions').hide(); // Hide bulk actions
        $('#selected-lead-count').hide(); // Hide selected leads text
        $('#selected-lead-text').hide();
    } else {
        $('.bulk-actions').show(); // Show bulk actions
        $('#selected-lead-count').show(); // Show selected leads text
        $('#selected-lead-text').show();
    }
  }

  // When "Select All" checkbox is checked or unchecked
  selectAllCheckbox.on('change', function() {
    const isChecked = $(this).is(':checked');
    refreshRowCheckboxes().prop('checked', isChecked);
    updateSelectAllState();
    updateSelectedLeadCount(); // Update count when selecting all
  });

  // When any row checkbox is checked or unchecked
  tableBody.on('change', '.lead-checkbox', function() {
    updateSelectAllState();
    updateSelectedLeadCount(); // Update count on individual checkbox change
  });

  // Function to update "Select All" checkbox based on row checkboxes state
  function updateSelectAllState() {
    const rowCheckboxes = refreshRowCheckboxes();
    const totalCheckboxes = rowCheckboxes.length;
    const checkedCheckboxes = rowCheckboxes.filter(':checked').length;

    if (checkedCheckboxes === totalCheckboxes) {
        selectAllCheckbox.prop({ indeterminate: false, checked: true }).removeClass('indeterminate');
    } else if (checkedCheckboxes === 0) {
        selectAllCheckbox.prop({ indeterminate: false, checked: false }).removeClass('indeterminate');
    } else {
        selectAllCheckbox.prop({ indeterminate: true, checked: false }).addClass('indeterminate');
    }
  }

  const nextPageUrl = () => {
    const currentUrl = new URL(window.location.href);
    const currentPage = parseInt(currentUrl.searchParams.get("page")) || 1;
    currentUrl.searchParams.set("page", currentPage + 1);
    console.log('Next page URL:', currentUrl.toString()); // Debugging line
    return currentUrl.toString();
  };

  // Scroll event on the wrapper
  $('.table-wrapper').on('scroll', function() {
    if (!loading && $(this).scrollTop() + $(this).outerHeight() >= this.scrollHeight - 10) {
      loading = true;
      loadingIcon.show();

      $.get(nextPageUrl(), null, function(data) {
          tableBody.append(data);
          loading = false;
          loadingIcon.hide();
          // Re-assign rowCheckboxes after new data is added
          updateSelectAllState(); // Update state after new data
          updateSelectedLeadCount(); // Update selected lead count after new data
      }).fail(function() {
          loading = false;
          loadingIcon.hide(); // Hide loading on error
      });
    }
  });

  // Initial call to toggle bulk actions based on the initial state
  updateSelectedLeadCount();
  });

  $(document).on('click', '#cstm-delete-btn', function() {
    const selectedIds = getSelectedLeadIds();
    if (selectedIds.length === 0) {
        alert('No leads selected for deletion.');
        return;
    }
    
    $.ajax({
      url: '/api/leads/lead_bulk_destroy', // Update this path if needed
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') // Add CSRF token here
      },
      data: JSON.stringify({ lead_ids: selectedIds }),
      success: function(response) {
        location.reload(); // Refresh the page
      },
      error: function(xhr) {
        alert('Error deleting leads: ' + xhr.responseText);
      }
    });
  });

  $(document).on('click', '#cstm-archive-btn', function() {
    const selectedIds = getSelectedLeadIds();
    if (selectedIds.length === 0) {
      alert('No leads selected for archiving.');
      return;
    }
    
    $.ajax({
      url: '/api/leads/lead_bulk_archive', // Make sure this URL matches your route
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') // Add CSRF token
      },
      data: JSON.stringify({ lead_ids: selectedIds }),
      success: function(response) {
        location.reload(); // Refresh the page
      },
      error: function(xhr) {
        alert('Error deleting leads: ' + xhr.responseText);
      }
    });
  });

function getSelectedLeadIds() {
  return $('.lead-checkbox:checked').map(function() {
      return parseInt(this.value, 10); // Convert the value to an integer
  }).get();
}
// Delegated event to handle dynamically added checkboxes
$(document).on('change', 'input[type="checkbox"]', function() {
  // 'this' refers to the checkbox that was changed
  const selectedLeadCount = document.getElementById('selected-lead-count');
  const bulkActions = $('#bulk-actions');
  const count = parseInt(selectedLeadCount.textContent) || 0;

  if(count > 0){
    bulkActions.show();
    $('#selected-lead-text').show();
    $('#selected-lead-count').show(); // Show selected leads text
  } else {
    bulkActions.hide(); // Hide bulk actions
    $('#selected-lead-text').hide();
    $('#selected-lead-count').hide(); // Hide selected leads text
  }
});
$(function() {
  var _attachAddressAutoComplete = function($el) {
    $el.addressAutoComplete({
      source: function(term, callback) {
        var address = _buildRequestAddress($el);
        $.getJSON("https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURIComponent(address) + "&key=" + GOOGLE_API_KEY, function(data, status) {
          if (status === "success" && data.status === "OK") {
            _buildAddressSuggestions(data.results, callback);
          }
        });
      },
      select: function(event, ui) {
        event.preventDefault();
        _fillAddress($el, ui.item.address_components);
      },
      minLength: 3,
      delay: 500
    });
  };

  var _buildRequestAddress = function($el) {
    var address = _getAddressElements($el);
    var country = address.country.val();
    return [(address.address1.val() || "") + " " + (address.address2.val() || ""), address.city.val(), address.state.val(), country].join(", ");
  };

  var _getAddressElements = function($el) {
    var name = $el.attr("name");
    var lastSqBracket = name.lastIndexOf("[");
    var namespace = name.substring(0, lastSqBracket);
    var result = {};

    result.address1 = $("input[type=\"text\"][name=\"" + namespace + "[address]\"]");
    if (result.address1.length === 0) {
        result.address1 = $("input[type=\"text\"][name=\"" + namespace + "[address1]\"]");
    }
    if (result.address1.length === 0) {
        result.address1 = $("input[type=\"text\"][name=\"" + namespace + "[address_1]\"]");
    }

    // Address2
    result.address2 = $("input[type=\"text\"][name=\"" + namespace + "[address2]\"]");
    if (result.address2.length === 0) {
        result.address2 = $("input[type=\"text\"][name=\"" + namespace + "[address_2]\"]");
    }

    // City
    result.city = $("input[type=\"text\"][name=\"" + namespace + "[city]\"]");

    // County
    result.county = $("input[type=\"text\"][name=\"" + namespace + "[county]\"]");

    // State
    result.state = $("[name=\"" + namespace + "[state]\"]");

    // Country
    result.country = $("[name=\"" + namespace + "[country]\"]");

    // Zip Code
    result.zip_code = $("[name=\"" + namespace + "[zip_code]\"]");

    return result;
  };

  var _buildAddressSuggestions = function(geocodes, callback) {
    var suggestions = $.map(geocodes.slice(0, 5), function(geocode) {
      var lat = geocode.geometry.location.lat;
      var lng = geocode.geometry.location.lng;
      return {
        label: geocode.formatted_address,
        image_src: "https://maps.googleapis.com/maps/api/staticmap?center=" + encodeURIComponent(lat + "," + lng) + "&zoom=14&size=200x100&maptype=roadmap&sensor=false&key=" + GOOGLE_API_KEY,
        address_components: geocode.address_components
      };
    });

    callback(suggestions);
  };

  var _fillAddress = function($el, components) {
    var city = undefined;
    var country = undefined;
    var county = undefined;
    var state = undefined;
    var street = undefined;
    var streetNumber = undefined;
    var zip_code = undefined;
    var premise = undefined;
    var address = _getAddressElements($el);

    $.each(components, function(idx, component) {
      if ($.inArray("street_number", component.types) >= 0) {
          streetNumber = component.long_name;
      }
      if ($.inArray("route", component.types) >= 0) {
          street = component.long_name;
      }
      if ($.inArray("locality", component.types) >= 0) {
          city = component.long_name;
      }
      if ($.inArray("administrative_area_level_1", component.types) >= 0) {
          state = component.short_name;
      }
      if ($.inArray("administrative_area_level_2", component.types) >= 0) {
          county = component.long_name;
      }
      if ($.inArray("country", component.types) >= 0) {
          country = component.short_name;
      }
      if ($.inArray("postal_code", component.types) >= 0) {
          zip_code = component.long_name;
      }
      if ($.inArray("premise", component.types) >= 0) {
          premise = component.long_name;
      }
    });

    var fullStreetAddress = premise || '';
    if (streetNumber) {
        fullStreetAddress = fullStreetAddress  + " " + streetNumber;
    }
    if (street) {
        fullStreetAddress += fullStreetAddress ? ", " + street : street;  // Add street if it exists
    }

    // Update the address elements
    address.address1.val(fullStreetAddress);
    address.city.val(city);
    address.state.val(state);
    address.county.val(county);
    address.country.val(country);
    address.zip_code.val(zip_code);
  };

  var selector = "input[type=\"text\"][name$=\"[address]\"], input[type=\"text\"][name$=\"[address1]\"], input[type=\"text\"][name$=\"[address_1]\"], " +
      "input[type=\"text\"][name$=\"[address2]\"], input[type=\"text\"][name$=\"[address_2]\"], " +
      "input[type=\"text\"][name$=\"[city]\"], input[type=\"text\"][name$=\"[county]\"], " +
      "input[type=\"text\"][name$=\"[zip_code]\"]";

  $(document).on("focusin", selector, function() {
    var $el = $(this);
    if (!$el.hasClass("ui-autocomplete-input")) {
      _attachAddressAutoComplete($el);
    }
  });

  $.widget("custom.addressAutoComplete", $.ui.autocomplete, {
    _renderItem: function(ul, item) {
      return $("<li></li>").data("item.autocomplete", item)
        .append($("<a class='top-align'></a>").html(item.label + "&nbsp;<img src=\"" + item.image_src + "\">"))
        .appendTo(ul);
    }
  });
});

