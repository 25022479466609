<template lang="pug">
tr
  td(class="text-sm px-4")
    input(type="checkbox" :checked="isSelected" :class="lead-checkbox" @change="$emit('toggleSelect', lead.id)")
  td(class="text-sm")
    a(:href="'/leads/' + lead.id + '/edit'" class="align-items-center")
      h6(class="ms-3 my-auto d-flex align-items-center")
        i(class="fas fa-user text-secondary me-3")
        .word-wrapper(style="flex: 1; display: flex; align-items: center; font-size: 1.1rem;")
          span(v-html="lead.name")
      div(class="ms-3 my-auto" style="display: flex; align-items: center; flex-wrap: wrap; max-width: 200px !important ")
        span(class="badge bg-gradient-secondary" style="margin: 1px" v-for="tag_name in lead.tag_list") {{ tag_name }}
  td
    span(class="badge bg-warning ms-3 my-auto mt-1" v-if="lead.status === 1") warm
    span(class="badge bg-danger ms-3 my-auto mt-1" v-else-if="lead.status === 2") hot
    span(class="badge bg-primary ms-3 my-auto mt-1" v-else-if="lead.status === 0") cold
    span(class="ms-3 my-auto mt-1" v-else)

  td(class="text-sm px-4") {{ lead.created_at }}
  td(class="text-sm px-4") {{ lead.phone }}
  td(class="text-sm px-4") {{ lead.home_phone }}
  td(class="text-sm px-4") {{ lead.business_phone }}

  td(class="text-sm text-center icon-container")
    a(href="#" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Flag" @click.stop.prevent="flag")
      i(class="fa fa-ban text-secondary" title="flag")    

    a(href="javascript:;" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Delete" @click.stop.prevent="remove")
      i(class="fas fa-trash text-secondary" title="Delete")

    a(v-if="lead.archived" href="#" data-bs-toggle="tooltip" data-bs-original-title="Unarchive" @click.stop.prevent="unarchive")
      i(class="fas fa-undo text-secondary" title="Unarchive")

    a(v-else href="#" data-bs-toggle="tooltip" data-bs-original-title="Archive" @click.stop.prevent="archive")
      i(class="fas fa-arrow-down text-secondary" title="Archive")

</template>

<script>
import archiveData from '../../hooks/archiveDeleteData'

export default {
  props: {
    lead: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { archive, unarchive, remove, flag } = archiveData('/api/leads', props.lead.id, emit)

    return { archive, unarchive, remove, flag }
  }
}
</script>
<style>
.word-wrapper {
  display: block;
  white-space: normal;
  word-break: break-word;
  text-align: left;
  max-width: 150px;
  display: flex;
  align-items: center;
}
.fa-ban{
  font-size: 14px !important;
  margin: 0 !important;
}
.icon-container{
  padding-left: 20px !important;
}

</style>