import axios from "axios";
import { ref } from "vue";

export default function openDealData(endpoint_url, item_id, emit) {
  const data = ref([]);

  const getAuthenticityToken = () => {
    const tokenElement = document.querySelector('input[name="authenticity_token"]') ||
        document.querySelector('meta[name="csrf-token"]');
    return tokenElement ? tokenElement.content : '';
  };

  const openDeal = async () => {
    try {
      const csrfToken = getAuthenticityToken();
      const response = await axios.post(`${endpoint_url}/${item_id}/open`, {}, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      const { message, redirect_url } = response.data;

      if (message) {
        alert(message);  // Show the message from the server
      }

      if (redirect_url) {
        window.location.href = redirect_url;  // Redirect to the provided URL
      }

      // Emit the open event to parent component
      emit('open', item_id);
    } catch (e) {
      // Handle error case
      const errorMessage = e.response?.data?.message || 'An error occurred while opening the deal.';
      alert(errorMessage);  // Show an alert with the error message
      console.error('Error:', errorMessage);  // Log the error for debugging
    }
  };

  return {
    openDeal
  };
}
