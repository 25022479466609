import axios from "axios";
import { ref } from "vue";

export default function archiveData(endpoint_url, item_id, emit) {
  const data = ref([])

  const getAuthenticityToken = () => {
    return document.querySelector('input[name="authenticity_token"]') ||
        document.querySelector('meta[name="csrf-token"]').content
  }

  const archive = async () => {
    try {
      const response = await axios.get(`${endpoint_url}/${item_id}/archive`)

      emit('archive', item_id)
    } catch(e) {
      alert('An error occurred while archiving.')
    }
  }

  const flag = async () => {
    try {
      const response = await axios.get(`${endpoint_url}/${item_id}/flag`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      const { redirect_url, message } = response.data;

      if (message) {
        alert(message); 
      }
      if (redirect_url) {
        window.location.href = redirect_url;
      }
    } catch (e) {
      const errorMessage = e.response?.data?.message || "An error occurred while deleting contact and reporting phone number.";

      alert(errorMessage);  // Display the error message
      console.error("Error:", errorMessage);
    }
  }

  const unarchive = async () => {
    try {
      const response = await axios.get(`${endpoint_url}/${item_id}/unarchive`)

      emit('unarchive', item_id)
    } catch(e) {
      alert('An error occurred while unarchiving.')
    }
  }

  const remove = async () => {
    try {
      const response = await axios.delete(`${endpoint_url}/${item_id}`, { data: { authenticity_token: getAuthenticityToken() }})

      emit('remove', item_id)
    } catch(e) {
      alert('An error occurred while deletion.')
    }
  }

  return {
    archive, unarchive, remove, flag
  }
}
