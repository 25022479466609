<template lang="pug">
.gallery
  .row
    .col-8.inv-images-section
      .card
        .card-header.pb-0
          h4 Vehicle images
        .card-body
          #inventory_gallery(v-cloak :class='draggingClass')
            ul.thumbnails(v-if='state !== "loading"')
              draggable(v-model='images' item-key='id' @change='imageOrderChanged' @start='this.setDraggingClass("dragging")' @end='this.setDraggingClass("")')
                template(#item="{ element }")
                  li
                    div(v-if='element.id')
                      a.thumbnail.gallery(:href="element.original_url")
                        img(:src="element.thumb_url" :data-rjs="element.thumb_at2x_url")

                    div.uploading(v-if='!element.id')
                      content_loader(:width="150" :height="150" :speed="1")
                      rect(width='100' height='94' rx='2' ry='2')

                    div.delete-controls
                      a(@click="deleteImage(element.id)" :disabled="!(element.id && state == 'ready')")
                        i.fas.fa-trash

            #loading(v-if='state == "loading"')
              content_loader(:speed='1' :primaryColor='this.primaryLoadingColor' :secondaryColor='this.secondaryLoadingColor')
                rect(x='0' y='0' width='80' height='80' rx='2' ry='2')
                rect(x='100' y='0' width='80' height='80' rx='2' ry='2')
                rect(x='200' y='0' width='80' height='80' rx='2' ry='2')
                rect(x='300' y='0' width='80' height='80' rx='2' ry='2')

            #no-images(v-if='state == "ready" && images.length == 0')
              | No images

    .col-4
      .card
        .card-header.pb-0
          h4 Add photos

        .card-body.pt-0
          .inner
            #image-upload
              .dropzone(@drop.prevent='photosDropped' @dragover.prevent='')
                p Drop your photos
                div
                  button(@click='$refs.fileSelector.click()' class='btn btn-inverse' :disabled='state !== "ready"') or click here
              input(type='file' multiple='multiple' @change='onFileSelected' ref='fileSelector' style='display: none;')

      .card.mt-3(v-if = "images.length !== 0")
        .card-header.pb-1
          h4 Delete all photos

        .card-body.pt-0
          p Clicking the button will remove all photos for this vehicle
          p.mb-0
            button.btn.btn-danger(@click = "deleteAll", :disabled = "state !== 'ready'")
              | Delete All

</template>
<script>
  import { ContentLoader } from 'vue-content-loader'
  import axios from 'axios'
  import draggable from 'vuedraggable'
  import { Fancybox } from "@fancyapps/ui"
  import csrfToken from '../../mixins/csrfToken'

  export default {
    name: 'Gallery',
    mount_element: 'inventory-gallery-app',
    components: {
      'content_loader': ContentLoader,
      'draggable': draggable
    },
    mixins: [csrfToken],
    data () {
      return {
        selectedFiles: [],
        state: 'initial',
        api_url: gon.images_api_url,
        base_api_url: gon.images_base_api_url,
        images: [],
        draggingClass: ''
      }
    },
    mounted () {
      this.fetchImages()
      this.initFancybox()
      // this.scheduleUpdates()
    },
    computed: {
      darkModeEnabled() {
        return document.body.classList.contains("dark-version")
      },
      primaryLoadingColor() {
        return this.darkModeEnabled ? '#003550' : '#f9f9f9'
      },
      secondaryLoadingColor() {
        return this.darkModeEnabled ? '#0b5175' : '#ecebeb'
      }
    },
    methods: {
      setDraggingClass(value) {
        this.draggingClass = value
      },
      initFancybox() {
        Fancybox.bind("a.gallery",
                      {
                        groupAll : true
                      })
      },
      // areOverlaysProcessing() {
        // return this.images.filter(i => {return i.overlay_processing}).length > 0
      // },
      onFileSelected(e) {
        if(this.state != 'ready') return;

        this.selectedFiles = e.target.files;
        this.startUpload()
      },
      photosDropped(e) {
        if(this.state != 'ready') return;

        this.selectedFiles = e.dataTransfer.files;
        this.startUpload()
      },
      async startUpload() {
        if(!this.selectedFiles) return;

        this.createPlaceholders(this.selectedFiles)
        await this.uploadFiles(this.selectedFiles)
        this.$refs.fileSelector.value = ''
      },
      createPlaceholders(files) {
        for(const file of files) {
          let image = {file: file}
          this.images.push(image);
        }
      },
      async uploadFiles() {
        for(const image of this.images) {
          if (!image.id) {
            await this.uploadFile(image)
          }
        }
      },
      async uploadFile(image) {
        let fd = new FormData()
        fd.append('image', image.file)
        fd.append('authenticity_token', document.querySelector('meta[name="csrf-token"]').content)
        
        this.state = 'busy'
        return axios.post(this.base_api_url + '.json', fd)
          .then(response => {
            let index = this.images.findIndex((i) => {return !i.id})

            this.images[index].id = response['data']['id']
            this.images[index].thumb_url = response['data']['thumb_url']
            this.images[index].thumb_url_at_2x = response['data']['thumb_url_at_2x']
            this.images[index].original_url = response['data']['original_url']
            this.images[index].overlay_processing = response['data']['overlay_processing']

            this.$forceUpdate()
            this.becomeReady()
          })
          .catch(error => {
          console.error('Upload failed:', error.response.data); // Check for specific errors
        });
      },
      async fetchImages() {
        this.images = []
        this.state = 'loading'

        await axios
          .get(this.api_url)
          .then(response => {
            response.data.images.forEach(i => {
              this.images.push(i)
            })

            this.becomeReady()
          })
      },

      deleteImage(id) {
        if (confirm("Are you sure?")) {
          let index = this.images.findIndex(i => {return i.id == id})
          axios
            .delete(this.base_api_url + `/${id}.json`, { data: { authenticity_token : document.querySelector('meta[name="csrf-token"]').content }})
            .then(response => {
              this.images.splice(index, 1)
            })
        }
      },
      deleteAll() {
        if (confirm("Are you sure? This can't be undone!")) {
          this.state = 'busy'

          axios.delete(this.base_api_url + '/destroy_all.json', { data: {authenticity_token : document.querySelector('meta[name="csrf-token"]').content }})
              .then(response => {
                    this.images = []
                    this.becomeReady()
              })
        }
      },
      async imageOrderChanged(e) {
        this.state = 'busy'

        await axios.patch(this.base_api_url + '/order.json',
                        {
                          order: this.images.map(function(i) {return i.id}),
                          authenticity_token : document.querySelector('meta[name="csrf-token"]').content
                        }
                        )
                    .then(response => {
                      this.becomeReady()
                    })
      },
      becomeReady() {
        this.state = 'ready'
      },
      // async backgroundFetch() {
      //   let result = []

      //   await axios
      //     .get(window.location.href + '.json')
      //     .then(response => {
      //       response.data.images.forEach(i => {
      //         result.push(i)
      //       })
      //     })

      //   return result
      // },
      // scheduleUpdates() {
      //   setTimeout(this.checkUpdates, 2000)
      // },
      // updateImages(new_images) {
      //   for(const ni of new_images) {
      //     let index = this.images.findIndex(i => {return i.id == ni.id})

      //     if (index !== undefined && this.images[index].original_url != ni.original_url) {
      //       this.images[index].id = ni.id
      //       this.images[index].thumb_url = ni.thumb_url
      //       this.images[index].thumb_url_at_2x = ni.thumb_url_at_2x
      //       this.images[index].original_url = ni.original_url
      //       this.images[index].overlay_processing = ni.overlay_processing

      //       this.$forceUpdate()
      //     }
      //   }
      // },
      // async checkUpdates() {
      //   if (this.state == 'ready') {
      //     let new_images = []
      //     await this.backgroundFetch().then(result => {new_images = result})
      //     this.updateImages(new_images)
      //   }

      //   setTimeout(this.checkUpdates, 10000)
      // }
    }
  }
</script>
<style lang="sass" scoped>
  ul
    padding: 0

  ul > div
    display: flex
    flex-wrap: wrap

  li
    list-style: none
    width: 150px
    margin: 10px
    position: relative

    img
      width: 150px
      height: 150px

    .delete-controls
      position: absolute
      bottom: 10px
      right: 10px
      opacity: 0
      transition: opacity 0.1s ease-out

      a
        border-radius: 5px
        color: #e4430c !important
        background: #fff
        border: 1px solid lightgray
        padding: 3px 7px
        cursor: pointer

    &.sortable-ghost, &.sortable-chosen

      .delete-controls
        opacity: 0

  li:hover
    .delete-controls
      opacity: 1
      transition: opacity 0.2s ease-in

      &:hover
        a
          background: #ffe1e1
          color: #bb3101 !important
          transition: all 0.2s ease-in
          transition-property: backround-color color

  .dragging ul li, ul li:active
    .delete-controls

  .dark-version ul li
    a:not(.dropdown-item):not(.choices__item):not(.leaflet-control-zoom-in):not(.leaflet-control-zoom-out):not(.btn):not(.nav-link):not(.fixed-plugin-button)
      color: #e4430c !important

      &:hover
        color: #bb3101 !important


  .dropzone
    text-align: center
    border: 1px dotted gray
    border-radius: 5px

  .dark-version .dropzone
    background: #003550

    button
      background: #fb6340b0
      color: white

  #no-images
    text-align: center
    margin: 0 0 50px
</style>
