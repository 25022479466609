import axios from "axios";
import { ref } from "vue";

export default function closeDealData(endpoint_url, item_id, emit) {
  const data = ref([]);

  const getAuthenticityToken = () => {
    const tokenElement = document.querySelector('input[name="authenticity_token"]') ||
        document.querySelector('meta[name="csrf-token"]');
    return tokenElement ? tokenElement.content : '';
  };

  const closeDeal = async () => {
    try {
      const csrfToken = getAuthenticityToken();
      const response = await axios.post(`${endpoint_url}${item_id}/close`, {}, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      const { redirect_url, message } = response.data;

      if (message) {
        alert(message); 
      }
      if (redirect_url) {
        window.location.href = redirect_url;
      }
    } catch (e) {
      const errorMessage = e.response?.data?.message || "An error occurred while closing the deal.";

      alert(errorMessage);  // Display the error message
      console.error("Error:", errorMessage);
    }
  };

  return {
    closeDeal
  };
}