import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { date: String }

  connect() {
    this.updateTimeago()
    // Update every minute (like jQuery timeago)
    setInterval(() => this.updateTimeago(), 60000)
  }

  updateTimeago() {
    const formatted = this.timeago(this.dateValue)
    this.element.innerHTML = formatted
  }

  timeago(dateString) {
    const date = new Date(dateString)
    const distanceMillis = new Date() - date
    return this.inWords(distanceMillis)
  }

  inWords(distanceMillis) {
    const strings = {
      seconds: "less than a minute",
      minute: "about a minute",
      minutes: "%d minutes",
      hour: "about an hour",
      hours: "about %d hours",
      day: "a day",
      days: "%d days",
      month: "about a month",
      months: "%d months",
      year: "about a year",
      years: "%d years",
      ago: "ago",
    }

    const seconds = Math.abs(distanceMillis) / 1000
    const minutes = seconds / 60
    const hours = minutes / 60
    const days = hours / 24
    const months = days / 30
    const years = days / 365

    let words

    // Determine the correct string to return based on the distanceMillis
    if (seconds < 45) {
      words = strings.seconds
    } else if (seconds < 90) {
      words = strings.minute
    } else if (minutes < 45) {
      words = strings.minutes.replace("%d", Math.round(minutes))
    } else if (minutes < 90) {
      words = strings.hour
    } else if (hours < 24) {
      words = strings.hours.replace("%d", Math.round(hours))
    } else if (hours < 42) {
      words = strings.day
    } else if (days < 30) {
      words = strings.days.replace("%d", Math.round(days))
    } else if (days < 45) {
      words = strings.month
    } else if (days < 365) {
      words = strings.months.replace("%d", Math.round(months))
    } else if (years < 1.5) {
      words = strings.year
    } else {
      words = strings.years.replace("%d", Math.round(years))
    }

    return `${words} ${strings.ago}`
  }
}
