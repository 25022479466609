import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ["choices", "contactInvSwitcher", "choicesNoSort", "choicesTags", "accountSwitcher"]

  choicesTargetConnected(element) {
    new Choices(element, { searchEnabled: false });
  }

  contactInvSwitcherTargetConnected(element) {
    const contactDropdown = new Choices(element, {
      searchEnabled: true,
      searchPlaceholderValue: "Search...",
      placeholder: true,
      placeholderValue: "Search for contacts",
      itemSelectText: "",
    });

    const clearSelectionButton = document.getElementById("add-contact-link");

    if (clearSelectionButton) {
      clearSelectionButton.addEventListener("click", function (e) {
        e.preventDefault();
        
        contactDropdown.removeActiveItems();

      });
    }
  }

  invSwitcherTargetConnected(element) {
    new Choices(element, { searchEnabled: true, searchPlaceholderValue: "Search..." });
  }

  choicesNoSortTargetConnected(element) {
    new Choices(element, { searchEnabled: false, shouldSort: false });
  }

  choicesTagsTargetConnected(element) {
    new Choices(element, { delimiter: ',', addItems: true, duplicateItemsAllowed: false, editItems: true, removeItemButton: true });
  }

  accountSwitcherTargetConnected(element) {
  new Choices(element, { searchEnabled: true, searchPlaceholderValue: "Search..." });

    element.addEventListener('change', function(e) {
      Turbo.visit(`/account-switcher/choose/${e.target.value}`)
    })
  }
}
